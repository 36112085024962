// @mui
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextSection from 'src/components/TextSection';
import HeroSmall from 'src/sections/HeroSmall';
import Page from '../components/Page';

import {
  SectionContact
} from '../sections';


const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  zIndex: 10
}));

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(14),
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    paddingTop: theme.spacing(14),
  },
}));

export default function VacaturesPagina() {
  return (
    <Page title="Zwemschool Midden | Lessen">
      <HeroSmall
        subtitleProps={
          {
            subtitle1: 'Vacatures',
            subtitle2: 'Werken bij het leukste team'
          }
        }
        imageUrl='/assets/images/temp-1.jpeg'
      />
      <ContentStyle>
        <RootStyle>

          <Typography color={'primary'} variant='overline'>Vacature</Typography>

          <TextSection
            
            title='Beginnend Zweminstructeur'
            content={[
              'Lijkt het je leuk om in het weekend een paar uur extra te werken met kinderen, en een bijdrage te leveren aan een zwemveiliger Nederland? Vind je gezelligheid in het team belangrijk & heb je affiniteit met bewegen?',
              'Neem vooral contact op, wij bespreken graag wat er mogelijk is!'
            ]}
          />

          <Box sx={{ mt: 0, mb: 0, textAlign: 'center', width: '100%' }}>
            <SectionContact gray subtitle='' />
          </Box>

        </RootStyle>
      </ContentStyle>
    </Page >
  );
}
