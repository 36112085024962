import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography, Divider, Box } from '@mui/material';
// hooks
import Image from '../components/Image';
import { MotionContainer, varFade } from '../components/animate';
import useResponsive from 'src/hooks/useResponsive';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(14),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------
const fakeDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
function renderSection(image: string, title: string, role: string, description: string, rtl: boolean, isMobile: boolean) {
  let textAlign = rtl ? 'end' : 'start';
  let sxPadding: any = rtl ? { pr: 6 } : { pl: 6 }

  if (isMobile) {
    textAlign = 'center';
    sxPadding = {}
  }

  return <m.div key={title} variants={varFade().inLeft}><Grid container sx={rtl ? { flexDirection: 'row-reverse' } : {}}>
    <Grid sx={{  }} item xs={12} md={5}>

        <Image style={{
          borderRadius: isMobile ? 4 : 0,
          height: isMobile ? 400 : 'unset'
        }} src={image} />
      
    </Grid>
    <Grid item xs={12} md={7} sx={{ ...sxPadding, }}>
      <Typography color={'#34495e'} sx={{ textAlign: textAlign, mt: isMobile ? 6 : 15 }} variant="h2">{title}</Typography>
      <Typography sx={{ mt: 0, color: 'text.secondary', textAlign: textAlign }} >{role}</Typography>
    </Grid>
  </Grid>

    {
      isMobile && <Divider sx={{ my: { xs: 8, lg: 10 } }} />
    }
    {
      !isMobile && <Divider sx={{ mb: 0 }} />
    }
  </m.div>
}

export default function SectionMeetTheTeam() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <RootStyle>
      <Container component={MotionContainer}>
        <Typography component="p" variant="overline" sx={{ textAlign: 'center', mb: 2, color: 'text.secondary' }}>
          Dream Team
        </Typography>

        <Typography variant="h2" sx={{ textAlign: 'center', color: '#34495e' }}>
          Meet the Team
        </Typography>
        <Divider sx={{ my: { xs: 8, lg: 15 } }} />
        {
          [
            {
              image: '/assets/team/Ashley.jpeg',
              title: 'Ashley',
              role: 'Zwemonderwijzeres / Directie',
              description: fakeDescription
            },
            {
              image: '/assets/team/Maarten.jpeg',
              title: 'Maarten',
              role: 'Zwemonderwijzer / Directie',
              description: fakeDescription
            },
            {
              image: '/assets/team/Lisa.jpeg',
              title: 'Lisa',
              role: 'Zwemonderwijzeres',
              description: fakeDescription
            },
            {
              image: '/assets/team/Juno.jpeg',
              title: 'Juno',
              role: 'Zwemonderwijzer',
              description: fakeDescription
            },
            {
              image: '/assets/team/Stefan.jpeg',
              title: 'Stefan',
              role: 'Zwemonderwijzer',
              description: fakeDescription
            },
            {
              image: '/assets/team/Edwin.jpeg',
              title: 'Edwin',
              role: 'Zwemonderwijzer',
              description: fakeDescription
            },
            {
              image: '/assets/team/Luc.jpeg',
              title: 'Luc',
              role: 'Zwemonderwijzer',
              description: fakeDescription
            }, {
              title: "Rules",
              image: "/assets/team/Rules.jpeg",
              role: "Zwemonderwijzer",
              description: ""
            }
          ].map((props, index) => renderSection(props.image, props.title, props.role, props.description, index % 2 === 0, !isDesktop))
        }

      </Container>
    </RootStyle >
  );
}